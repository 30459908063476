// WineExplained.js file

import React from 'react'
import { useEffect, useState, useRef } from 'react'
import Collapsible from 'react-collapsible';

import { isElementInViewport } from '../../components/functions'

export const WineExplained = ({ slice }) => {
    const [isInView, setIsInView] = useState(false)
    const ref = useRef(null)

    const data = slice.primary

    //create an event listener
    useEffect(() => {
        if(isElementInViewport(ref.current)){
            setIsInView(isElementInViewport(ref.current))
        }
        window.addEventListener('scroll',() => {
            if(isElementInViewport(ref.current)){
                setIsInView(true)
            }
        })
    }, [])

    return(
        <section className={(isInView)? "wine-explained py-0 py-lg-5 in-view" : "wine-explained py-0 py-lg-5"} ref={ref}>
            <div className='container pt-5 pb-3 pb-lg-0'>
                <div className='tlt-row row mb-5 pb-3 slide-up'>
                    <div className='tlt-wrapper col-lg-5'>
                        {(data.section_title.text)? <h2>{data.section_title.text}</h2> : null}
                    </div>
                    <div className="col-lg-7 d-flex align-items-center">
                        {(data.section_description.html)? <div className='desc-wrapper w-100 pt-3 pt-lg-0' dangerouslySetInnerHTML={{__html: data.section_description.html}}/> : null}
                    </div>
                </div>

                {/* Questions */}
                <div className='question-row row pt-3 pb-5 py-lg-5 mb-5 slide-up animation-delay-1'>
                    <div className='col-lg-5 d-flex align-items-end'>
                        {(data.section_image)?
                        <img src={data.section_image.url} alt={(data.section_image.alt)? data.section_image.alt : "Wine, Explained" } className="screen-only"/>
                        : null}
                    </div>
                    <div className='col-lg-7'>
                        {/* FAQ Collapsible */}
                        {data.questions.items.map(( question, idx ) => {
                            return(
                                <EachQuestion question={question.faq_question} answer={question.enter_faq_answer} idx={idx} key={"each-question-"+idx} />
                            )
                        })}
                    </div>
                </div>
            </div>
        </section>
    )
}

const EachQuestion = ({ question, answer, idx }) => {
    const clickDatalayer = (data) => {
        //Data layer - FAQ clicked
        window.dataLayer = window.dataLayer || []
        window.dataLayer.push(data)
    }

    return(
        <Collapsible 
            trigger={ (question)? question.text : "" }
            onOpen={() => {
                let dl = {
                    'event': 'faq_clicked',
                    'faq_title': (question)? question.text : ""
                }
                clickDatalayer(dl)
            }}
        >
            {(answer)?
                <div dangerouslySetInnerHTML={{ __html : answer.html }} />
            : null}
        </Collapsible>
    )
}