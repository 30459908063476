import React, {useRef, useState, useEffect} from 'react'
import { Link } from 'gatsby'

import { isElementInViewport } from '../components/functions'

export const WhereToBuyModule = ({slice}) => {
    const [isInView, setIsInView] = useState(false)
    const ref = useRef(null)

    const data = slice.primary

     //create an event listener
     useEffect(() => {
        if(isElementInViewport(ref.current)){
            setIsInView(isElementInViewport(ref.current))
        }
        window.addEventListener('scroll',() => {
            if(isElementInViewport(ref.current)){
                setIsInView(true)
            }
        })
    }, [])

    const clickDatalayer = (data) => {
        //Data layer - Product details clicked
        window.dataLayer = window.dataLayer || []
        window.dataLayer.push(data)
    }

    return (
        <section className={(isInView)? 'where-to-buy-module overflow-hidden pt-5 pt-lg-0 in-view': 'where-to-buy-module overflow-hidden pt-5 pt-lg-0'} ref={ref}>
            <div className='row mx-0 w-100'>
                <div className='col-lg-6 p-0'>
                    <div className="pl-3 pr-5 content-wrapper d-flex align-items-center">
                        <div className='pb-5 pb-lg-0 slide-up'>
                            <div className='tlt-wrapper pb-4'>
                                <h2>{data.section_title.text}</h2>
                            </div>
                            <div className='desc-wrapper' dangerouslySetInnerHTML={{__html: data.section_desc.html}}/>
                            {(data.button_link.link_type === "Document" && data.button_link.uid)?
                                <Link
                                    to={"/" + data.button_link.uid}
                                    onClick={() => {
                                        let pageHost = new URL(window.location.href)
                                        let pageUrl = pageHost.host;
                                        pageUrl +=  (data.button_link.uid === "5v")? "/5V" : "/" + data.button_link.uid;

                                        var referral = "HP"
                                        if(pageHost.pathname.replace("/", "") === "about-us"){
                                            referral = "about us page"
                                        }else if(pageHost.pathname.replace("/", "") === "find-your-wine"){
                                            referral = "find your wine page"
                                        }
                                        let dl = {
                                            'event': 'where_to_buy_clicked',
                                            'brand_name': '',
                                            'product_name': '',
                                            'page_name': pageUrl,
                                            'referral': referral,
                                            'tile_position': '',
                                            'page_location': window.location.href
                                        }
                                        clickDatalayer(dl)
                                    }}
                                >
                                    <button className='btn btn-primary-2'>
                                        <span>{data.button_link_text.text}</span>
                                    </button>
                                </Link>
                                :
                                <a
                                    href={data.button_link.url}
                                    target={(data.button_link.target)? data.button_link.target : "_self"}
                                    onClick={(e) => {
                                        let pageHost = new URL(window.location.href)
                                        var referral = "HP"
                                        if(pageHost.pathname.replace("/", "") === "about-us"){
                                            referral = "about us page"
                                        }else if(pageHost.pathname.replace("/", "") === "find-your-wine"){
                                            referral = "find your wine page"
                                        }

                                        let dl = {
                                            'event': 'where_to_buy_clicked',
                                            'brand_name': '',
                                            'product_name': '',
                                            'page_name': data.button_link.url,
                                            'referral': referral,
                                            'tile_position': '',
                                            'page_location': window.location.href
                                        }
                                        clickDatalayer(dl)
                                    }}
                                >
                                    <button className='btn btn-primary-2'>
                                        <span>{data.button_link_text.text}</span>
                                    </button>
                                </a>
                            }
                        </div>
                    </div>
                </div>
                <div className='col-lg-6 p-0 overflow-hidden'>
                    <img src={data.section_image.url} alt={(data.section_image.alt)? data.section_image.alt : "Where To Buy" } width="100%" className='zoom-out-wo-opacity'/>
                </div>
            </div>
        </section>
    )
}