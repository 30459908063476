import { OurBrandsSection } from '../OurBrandsSection'
import { WhereToBuyModule } from '../WhereToBuyModule'
import { WhatAreVQAWine } from './WhatAreVQAWine'
import { WineExplained } from './WineExplained'

export const components = {
    our_brands_section: OurBrandsSection,
    where_to_buy_module: WhereToBuyModule,
    what_are_vqa_wine_section: WhatAreVQAWine,
    wine_explained_section: WineExplained
}