import React, {useRef, useState, useEffect} from 'react'
import { Link } from 'gatsby'
import { isElementInViewport } from '../components/functions'

export const OurBrandsSection = ({slice}) => {
    const [isInView, setIsInView] = useState(false)
    const ref = useRef(null)

    const data = slice.primary;

    //create an event listener
    useEffect(() => {
        if(isElementInViewport(ref.current)){
            setIsInView(isElementInViewport(ref.current))
        }
        window.addEventListener('scroll',() => {
            if(isElementInViewport(ref.current)){
                setIsInView(true)
            }
        })
    }, [])

    const clickDatalayer = (data) => {
        //Data layer - Product details clicked
        window.dataLayer = window.dataLayer || []
        window.dataLayer.push(data)
    }

    return (
        <section className={(isInView)? "container-fluid container_our_brands in-view": "container-fluid container_our_brands"} ref={ref}>
            <div className="container">
                <div className="row pb-5 slide-up">
                    <div className="col-12 col-lg-6 tlt-wrapper">
                        <h2>{data.section_title.text}</h2>
                    </div>
                    <div className='col-12 col-lg-6 desc-wrapper'>
                        <p>{data.section_desc.text}</p>
                    </div>
                </div>
                <div className="row slide-up animation-delay-1 justify-content-center">
                    {
                        data.brand_link_image_text_group.map((item, index) => {
                            return(
                                <div key={`col-${index}`} className='col-6 col-lg-3 brand-item p-0'>
                                    {(item.brand_link.link_type === "Document" && item.brand_link.uid)?
                                    <Link to={(item.brand_link.uid === "5v")? "/5V" : "/" + item.brand_link.uid}
                                        className="d-flex flex-wrap justify-content-center h-100 text-center cursor-pointer brand_anchor_container"
                                        onClick={() => {
                                            //get full url
                                            let pageHost = new URL(window.location.href)
                                            let pageUrl = pageHost.host;
                                            pageUrl +=  (item.brand_link.uid === "5v")? "/5V" : "/" + item.brand_link.uid;
                                            let dl = {
                                                'event': 'product_details_clicked',
                                                'brand_name': `${item.brand_name.text.toLowerCase()}`,
                                                'product_name': '',
                                                'product_position': '',
                                                'page_url': pageUrl,
                                                'page_location': window.location.href
                                            }
                                            clickDatalayer(dl)
                                        }}
                                    >
                                        <div className="brand_image_container d-flex align-self-center justify-content-center">
                                            <img src={item.brand_image.url} alt={(item.brand_image.alt)? item.brand_image.alt : item.brand_name.text} width="60%" className="img-fluid brand_image" />
                                        </div>
                                        <div className="brand-item__content w-100 d-flex justify-content-center align-items-center position-relative">
                                            <span className="pt-4 w-100 explore-item">EXPLORE</span>
                                            <span className="w-100 brand_name">{item.brand_name.text}</span>
                                        </div>
                                    </Link>
                                    :
                                    <a href={item.brand_link.url} target={(item.brand_link.target)? item.brand_link.target : "_self"} 
                                        className="d-flex flex-wrap justify-content-center h-100 text-center cursor-pointer brand_anchor_container"
                                        onClick={() => {
                                            //get full url
                                            let dl = {
                                                'event': 'product_details_clicked',
                                                'brand_name': `${item.brand_name.text.toLowerCase()}`,
                                                'product_name': '',
                                                'product_position': '',
                                                'page_url': item.brand_link.url,
                                                'page_location': window.location.href
                                            }
                                            clickDatalayer(dl)
                                        }}
                                    >
                                        <div className="brand_image_container d-flex align-self-center justify-content-center">
                                            <img src={item.brand_image.url} alt={(item.brand_image.alt)? item.brand_image.alt : item.brand_name.text} width="60%" className="img-fluid brand_image" />
                                        </div>
                                        <div className="brand-item__content w-100 d-flex justify-content-center align-items-center position-relative">
                                            <span className="pt-4 w-100 explore-item">EXPLORE</span>
                                            <span className="w-100 brand_name">{item.brand_name.text}</span>
                                        </div>
                                    </a>
                                    }
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </section>
    )
}
