import React, {useState, useEffect} from 'react'
import { graphql } from 'gatsby'
import { SliceZone } from '@prismicio/react'

import Layout from '../components/Layout'
import { Seo } from '../components/Seo'
import { components } from '../slices/about-us'

import { withPrismicPreview } from "gatsby-plugin-prismic-previews";

//components
import AboutUsBanner from '../components/AboutUsBanner'

const AboutPage = ({ data }) => {
    /* REFORMAT DATA FOR SLICEZONE DATA */
    const pageData = data.prismicAboutUsPage.data
    const [sliceData, setSliceData] = useState([])

    useEffect(() => {
        /* ADD MODULES AND SECTIONS TO SLICEDATA */

        const newSlideData = []

        // What Are VQA Wine section
        let whatAreVQAWineSliceData = {
          slice_type: "what_are_vqa_wine_section",
          primary: {
            section_title: pageData.vaq_wine_section_title,
            section_description: pageData.vaq_wine_section_description,
            subsection: pageData.body1
          }
        }
        var checkWhatAreVQAWineDuplicateSlideData = newSlideData.map((item) => {
          if(item.slice_type === "what_are_vqa_wine_section") return true;
          return false;
        })
        if(!checkWhatAreVQAWineDuplicateSlideData.includes(true)) newSlideData.push(whatAreVQAWineSliceData)

        // Where to buy module
        //if where to buy module is set enable
        if(!data.prismicAboutUsPage.data.body[0].primary.disable_where_to_buy_module){
            let moduleWhereToBuy = data.prismicWhereToBuyModule.data
            let whereToBuySliceData = {
                slice_type: "where_to_buy_module",
                primary: {
                    section_title : moduleWhereToBuy.section_title,
                    section_desc: moduleWhereToBuy.section_description,
                    button_link: moduleWhereToBuy.button_link,
                    button_link_text: moduleWhereToBuy.button_link_text,
                    section_image: moduleWhereToBuy.section_image
                }
            }
            var checkWhereToBuyDuplicateSliceData = newSlideData.map((item) => {
              if(item.slice_type === "where_to_buy_module") return true;
              return false;
            })
            if(!checkWhereToBuyDuplicateSliceData.includes(true))  newSlideData.push(whereToBuySliceData)
        }

        // Wine,Explained Module
        if(pageData.body2[0].items.length > 0){
          let wineExplainedSlideData = {
            slice_type: "wine_explained_section",
            primary: {
              section_title: pageData.wine_explained_title,
              section_description: pageData.wine_explained_description,
              section_image: pageData.wine_explained_image,
              questions: pageData.body2[0]
            }
          }
          var checkWineExplainedDuplicateSlideData = newSlideData.map((item) => {
            if(item.slice_type === "wine_explained_section") return true;
            return false;
          })
          if(!checkWineExplainedDuplicateSlideData.includes(true)) newSlideData.push(wineExplainedSlideData)
        }

        // Our Brands Module
        var moduleOurBrand = data.prismicOurBrandsSection.data
        var ourBrandSliceData = {
            slice_type: "our_brands_section",
            primary: {
              section_title: moduleOurBrand.section_title,
              section_desc: moduleOurBrand.section_text_field,
              brand_link_image_text_group: moduleOurBrand.brand_link_image_text_group
            }
        }
        var checkOurBrandDuplicateData = newSlideData.map((item) => {
          if(item.slice_type === "our_brands_section") return true;
          return false;
        })
        if(!checkOurBrandDuplicateData.includes(true))  newSlideData.push(ourBrandSliceData)

        setSliceData(newSlideData)

        //Scroll to the top on new page
        document.documentElement.style.scrollBehavior = 'auto'
        window.scrollTo(0,0)

    }, [data.prismicAboutUsPage.data.body, 
      data.prismicOurBrandsSection.data, 
      data.prismicWhereToBuyModule.data, 
      pageData.body1, 
      pageData.vaq_wine_section_description, 
      pageData.vaq_wine_section_title,
      pageData.body2,
      pageData.wine_explained_description,
      pageData.wine_explained_image,
      pageData.wine_explained_title
    ])

    return (
        <Layout currentPage="about-us">
            <Seo
                title={(pageData.meta_title)? pageData.meta_title.text : null}
                description={(pageData.meta_description)? pageData.meta_description.text : null}
            />
            <AboutUsBanner
                title={pageData.banner_title.text}
                backgroundUrl={pageData.banner_background.url}
                subtitle={pageData.banner_subtitle.text}
                description={pageData.banner_description.html}
            />
            <SliceZone slices={sliceData} components={components} />
        </Layout>
    )
}

export default withPrismicPreview(AboutPage);

export const query = graphql`
    query AboutpageQuery {
        prismicAboutUsPage {
            _previewable
            data {
                banner_description {
                    text
                    html
                }
                banner_subtitle {
                    text
                }
                banner_title {
                    text
                }
                banner_background {
                    url
                    alt
                    gatsbyImageData
                }
                meta_description {
                    text
                }
                meta_title {
                    text
                }
                vaq_wine_section_description {
                    html
                    text
                }
                vaq_wine_section_title {
                    text
                }
                wine_explained_description {
                    html
                    text
                }
                wine_explained_image {
                    alt
                    url
                }
                wine_explained_title {
                    text
                }
                body2 {
                    ... on PrismicAboutUsPageDataBody2FaqQuestions {
                      id
                      slice_type
                      items {
                        enter_faq_answer {
                          text
                          html
                        }
                        faq_question {
                          text
                        }
                      }
                    }
                }
                body1 {
                    ... on PrismicAboutUsPageDataBody1VaqSubsection {
                      id
                      slice_type
                      primary {
                        vaq_subsection_title{
                          text
                        }
                        vaq_subsection_description {
                          html
                          text
                        }
                        vaq_subsection_image {
                          url
                          alt
                        }
                      }
                      items {
                        subsection_column_description {
                          html
                          text
                        }
                        subsection_column_title {
                          text
                        }
                      }
                    }
                }
                body {
                    ... on PrismicSliceType {
                        slice_type
                    }
                    ... on PrismicAboutUsPageDataBodyWhereToBuyModule {
                      id
                      primary {
                        disable_where_to_buy_module
                      }
                    }
                }
            }
        }
        prismicOurBrandsSection{
            _previewable
            data {
              section_title {
                text
              }
              section_text_field {
                text
              }
              brand_link_image_text_group {
                brand_image {
                  alt
                  copyright
                  url
                }
                brand_link {
                  size
                  slug
                  tags
                  target
                  type
                  uid
                  url
                  link_type
                  lang
                  isBroken
                  id
                }
                brand_name {
                  richText
                  text
                  html
                }
              }
            }
        }
        prismicWhereToBuyModule {
            _previewable
            data {
              section_title {
                text
              }
              section_description {
                text
                html
              }
              button_link {
                raw
                size
                slug
                tags
                target
                type
                uid
                url
                link_type
                lang
                isBroken
                id
              }
              button_link_text {
                text
              }
              section_image {
                alt
                url
              }
            }
        }
    }
`