import * as React from 'react'

const AboutUsBanner = ( { title, backgroundUrl, subtitle, description } ) => {
    return (
        <>
            <section className='in-view about-us__banner'>
                <div className='in-view about-us__banner-bg page-banner zoom-out-bg-wo-opacity' style={{backgroundImage: `url(${backgroundUrl})`}}></div>
                <div className="about-us__banner-content text-center slide-up d-flex justify-content-center align-items-center py-5">
                    <div className="py-0 py-lg-5 my-5 w-100">
                        {(subtitle)? 
                            <h6>{subtitle}</h6>
                        : null}
                        {(title)?
                            <h2><span className='px-5'>{title}</span></h2>
                        : null}
                        {(description)?
                            <div className='mx-auto desc-wrapper mt-lg-5 pt-3' dangerouslySetInnerHTML={{ __html: description }}/>
                        : null}
                    </div>
                </div>
            </section>
        </>
    )
}

export default AboutUsBanner