// WhatAreVQAWine.js file

import React from 'react'
import { useEffect, useState, useRef } from 'react'

import { isElementInViewport } from '../../components/functions'

export const WhatAreVQAWine = ({ slice }) => {
    const [isInView, setIsInView] = useState(false)
    const ref = useRef(null)
    const data = slice.primary

    //create an event listener
    useEffect(() => {
        if(isElementInViewport(ref.current)){
            setIsInView(isElementInViewport(ref.current))
        }
        window.addEventListener('scroll',() => {
            if(isElementInViewport(ref.current)){
                setIsInView(true)
            }
        })
    }, [])

    return(
        <>
            <section className={(isInView)? "what-are-vqa-wine py-0 py-lg-5 in-view" : "what-are-vqa-wine py-0 py-lg-5"} ref={ref}>
                <div className="container pt-5">
                    <div className='tlt-row row slide-up'>
                        <div className='tlt-wrapper col-lg-5'>
                            {(data.section_title.text)? <h2>{data.section_title.text}</h2> : null}
                        </div>
                        <div className="col-lg-7 d-flex align-items-center">
                            {(data.section_description.html)? <div className='desc-wrapper w-100 pt-3 pt-lg-0' dangerouslySetInnerHTML={{__html: data.section_description.html}}/> : null}
                        </div>
                    </div>
                </div>
            </section>
            {data.subsection.map((item, idx) => <SubSection key={"what-are-vaq-wine-" + idx} item={item} idx={idx} />)}
        </>
    )
}

const SubSection = ({ item, idx }) => {
    const [isInView, setIsInView] = useState(false)
    const ref = useRef(null)

    //create an event listener
    useEffect(() => {
        if(isElementInViewport(ref.current)){
            setIsInView(isElementInViewport(ref.current))
        }
        window.addEventListener('scroll',() => {
            if(isElementInViewport(ref.current)){
                setIsInView(true)
            }
        })
    }, [])

    return(
        <section className={(isInView)? 'what-are-vqa-wine-subsection in-view py-5' : 'what-are-vqa-wine-subsection py-5'} ref={ref}>
            <div>
                <div className='row pb-0 pb-lg-5'>
                    <div className='col-lg-6 d-flex justify-content-center align-items-center'>
                        <div className='content-wrapper slide-up'>
                            {(item.primary.vaq_subsection_title)?
                                <h3>{item.primary.vaq_subsection_title.text}</h3>
                            : null}
                            {(item.primary.vaq_subsection_description)?
                                <div className='desc-wrapper py-3 py-lg-5' dangerouslySetInnerHTML={{ __html: item.primary.vaq_subsection_description.html }}/>
                            : null}
                            {(item.items.length > 0)?
                            <div className='row'>
                                {item.items.map((column, column_idx) => (
                                    <div className='col-lg-6 py-2 py-lg-0' key={"column-" + column_idx}>
                                        <h6>{column.subsection_column_title.text}</h6>
                                        <div dangerouslySetInnerHTML={{ __html: column.subsection_column_description.html }} />
                                    </div>
                                ))}
                            </div>
                            : null}
                        </div>
                    </div>
                    <div className='col-lg-6 img-wrapper overflow-hidden pt-3 pb-4 py-lg-5'>
                        {(item.primary.vaq_subsection_image)? 
                            <img src={item.primary.vaq_subsection_image.url} alt={item.primary.vaq_subsection_image.alt} width="85%" />
                        : null}
                    </div>
                </div>
            </div>
        </section>
    )
}